@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    min-height: 100%;
    /* background-image: url("../public/pexels-daniel-absi-952670.jpg"); */
    @apply bg-gradient-to-br from-primary-dark  to-primary-main
 }

